import React from 'react'
import styled, { css } from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'

// Images
import Logo from 'img/logobg.inline.svg'
import Blockquote from 'img/blockquote.svg'

const Content = styled(ParseContent)<{ hasLogo: boolean }>`
  position: relative;
  z-index: 2;
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    margin-top: -0.5rem;
    ${(props) =>
      props.hasLogo &&
      css`
        text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        margin-top: -1rem;
        @media (min-width: 992px) {
          font-size: 48px;
          line-height: 62px;
        }
        @media (max-width: 991px) {
          font-size: 30px;
          line-height: 42px;
        }
      `};
  }

  & blockquote {
    position: relative;
    &:before {
      position: absolute;
      content: url(${Blockquote});
      top: -30px;
      @media (min-width: 576px) {
        left: -36px;
      }
      @media (max-width: 575px) {
        left: 0;
      }
    }
  }
`

const LogoWrapper = styled.div`
  position: relative;
  margin-top: -3.33rem;
  & svg {
    @media (max-width: 767px) {
      width: 250px;
      height: 250px;
    }
  }
  @media (max-width: 767px) {
    margin-top: -4.44rem;
  }
`

interface TextTwoColsProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextTwoCols: React.FC<TextTwoColsProps> = ({ fields }) => (
  <section className="mb-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-10">
          <div className="row">
            <div className="col-md-6">
              <Content
                content={fields.description}
                hasLogo={fields.haslogo || false}
              />
              <div>
                {fields.haslogo && (
                  <LogoWrapper>
                    <Logo />
                  </LogoWrapper>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <Content
                content={fields.seconddescription}
                hasLogo={fields.haslogo || false}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextTwoCols
