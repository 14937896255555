import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Images
import Quote from 'img/quote.inline.svg'

const ImageWrapper = styled.div`
  position: relative;

  @media (max-width: 767px) {
    max-width: 270px;
    top: 30px;
  }
`

const QuoteWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.color.light};
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  padding: 2rem 10rem 2rem 3rem;
  min-width: 370px;
  @media (min-width: 768px) {
    margin-left: -6rem;
  }

  @media (max-width: 575px) {
    padding: 2rem 8rem 2rem 3rem;
  }

  @media (max-width: 339px) {
    padding: 2rem 10rem 2rem 3rem;
  }
`

const TitleWrapper = styled.div`
  & h3 {
    font-size: 14px;
    margin-bottom: 0;
  }
`

const QuoteImage = styled(Plaatjie)`
  position: absolute;

  @media (min-width: 992px) {
    height: 397px;
    width: 314px;
    right: -80px;
  }
  @media (max-width: 991px) {
    height: 317px;
    width: 224px;
    right: -30px;
  }
  @media (max-width: 767px) {
    height: 297px;
    width: 214px;
    right: -30px;
  }
  @media (max-width: 575px) {
    height: 197px;
    width: 134px;
    right: 10px;
  }
  @media (max-width: 375px) {
    height: 147px;
    width: 100px;
  }
`

interface TextQuoteProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextQuote: React.FC<TextQuoteProps> = ({ fields }) => (
  <section className="mb-5">
    <div className="container py-lg-5">
      <div className="row justify-content-center">
        <div className="col-md-10">
          <div className="row align-items-center">
            <ImageWrapper className="col-md-5">
              <Plaatjie image={fields.image} alt="" />
            </ImageWrapper>
            <div className="col-md-7">
              <div className="row position-relative align-items-end">
                <div className="col-md-11">
                  <QuoteWrapper>
                    <TitleWrapper className="d-flex align-items-end mb-3">
                      <Quote className="me-4" />
                      <h3>{fields.quote?.title}</h3>
                    </TitleWrapper>
                    <ParseContent content={fields.quote?.description} />
                  </QuoteWrapper>
                </div>
                <QuoteImage
                  image={fields.quote?.image}
                  alt=""
                  className="position-absolute"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default TextQuote
