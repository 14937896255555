import React from 'react'

// Elements
import ParseContent from 'components/shared/ParseContent'

interface TextDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextDefault: React.FC<TextDefaultProps> = ({ fields }) => (
  <section className="mb-5 pb-lg-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className={`col-lg-${fields.columnwidth}`}>
          <ParseContent content={fields.description} />
        </div>
      </div>
    </div>
  </section>
)

export default TextDefault
