import React from 'react'

// Components
import TextDefault from 'components/flex/Text/TextDefault'
import TextTwoCols from 'components/flex/Text/TextTwoCols'
import TextQuote from 'components/flex/Text/TextQuote'

interface TextShellProps {
  // eslint-disable-next-line camelcase
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Text
  location?: any
}

interface TextProps {
  [key: string]: any
}

const TextShell: React.FC<TextShellProps> = ({ fields, location = {} }) => {
  const text: TextProps = {
    default: TextDefault,
    twocols: TextTwoCols,
    quote: TextQuote,
  }

  if (!fields.styletype || !text[fields.styletype]) {
    return null
  }

  const Component = text[fields.styletype]

  return (
    <Component
      key={`${location ? location.pathname : 'flex'}`}
      fields={fields}
      location={location}
    />
  )
}

export default TextShell
